// import _ from 'lodash'
// import React, { useState, useEffect } from 'react'
// import { useTranslation } from 'react-i18next'
// import { makeRequest } from '../../helpers'
// import ProductsView from './products-view'

// const ProductsController = (props) => {
//   const { slug } = props
//   const [category, setCategory] = useState(null)
//   const [listProducts, setListProducts] = useState([])
//   const [loading, setLoading] = useState(false)
//   const [productsByCategory, setProductsByCategory] = useState({})
//   const { i18n } = useTranslation()

//   const getListProductsByCategory = async () => {
//     setLoading(true)

//     const headers = {
//       'Content-Type': 'application/json',
//     }
//     // console.log("🚀 ~ use Effect getListProductsByCategory ~ slug:", slug)
//     console.log("🚀 ~ use Effect getListProductsByCategory ~ lang:", i18n.language)
//     makeRequest({
//       headers,
//       endPoint: 'productCategory',
//       params: {
//         slug,
//         locale: i18n.language,
//       },
//     })
//       .then(({ data }) => {
//         setProductsByCategory(data)
//         if (!_.isEmpty(data.children)) {
//           setListProducts(data.children)
//         } else {
//           setCategory(data.subcategory[0].title)
//           setListProducts(data.subcategory[0].products)
//         }
//         setLoading(false)
//       })
//       .catch((error) => {
//         console.error(error)
//         setLoading(false)
//       })
//   }
//   const onHandleFilter = (name) => {
//     setCategory(name)
//   }

//   useEffect(() => {
//     if (productsByCategory.subcategory) {
//       const item = _.head(
//         _.filter(productsByCategory.subcategory, { title: category }),
//       )
//       setListProducts(item.products)
//     }
//   }, [category, i18n.language])

//   useEffect(() => {
//     getListProductsByCategory()
//   }, [i18n.language])

//   const viewProps = {
//     data: productsByCategory,
//     loading,
//     listProducts,
//     category,
//     onHandleFilter,
//     language: i18n.language,
//   }

//   return <ProductsView {...viewProps} />
// }

// export default ProductsController
import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeRequest } from '../../helpers'
import ProductsView from './products-view'

const ProductsController = (props) => {
  const { slug } = props
  const [category, setCategory] = useState(null)
  const [listProducts, setListProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [productsByCategory, setProductsByCategory] = useState({})
  const [locale, setLocale] = useState('en')
  const { i18n } = useTranslation()

  const getListProductsByCategory = async (lang) => {
    setLoading(true)

    const locale = lang || 'en' // Use 'en' if lang is null
    const headers = {
      'Content-Type': 'application/json',
    }
    try {
      const { data } = await makeRequest({
        headers,
        endPoint: 'productCategory',
        params: {
          slug,
          locale,
        },
      })
      setProductsByCategory(data)
      console.log("🚀 ~ use data controller:", locale)
      console.log("🚀 ~ use data controller result:", data)
      if (!_.isEmpty(data.children)) {
        setListProducts(data.children)
      } else {
        setCategory(data.subcategory[0].title)
        setListProducts(data.subcategory[0].products)
      }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const onHandleFilter = (name) => {
    setCategory(name)
  }

  useEffect(() => {
    if (productsByCategory.subcategory) {
      const item = _.head(
        _.filter(productsByCategory.subcategory, { title: category }),
      )
      setListProducts(item.products)
    }
  }, [category])

  useEffect(() => {
    setLocale(i18n.language || 'en') // Update locale state based on i18n.language
  }, [i18n.language])

  useEffect(() => {
    const delayFetch = setTimeout(() => {
      getListProductsByCategory(locale)
    }, 500) // 500ms delay

    return () => clearTimeout(delayFetch) // Clear timeout if locale changes again before delay completes
  }, [locale, slug])

  const viewProps = {
    data: productsByCategory,
    loading,
    listProducts,
    category,
    onHandleFilter,
    language: locale, // Pass the locale state to the view
  }

  return <ProductsView {...viewProps} />
}

export default ProductsController


